/** @format */

class AppConstantsClass {
  readonly PRODUCTS = {
    CHICKEN_DOG_BOX: {
      name: '5kg Chicken Dog Box',
    },
    TREATS_BEEF: {
      name: 'Beef Liver Treats',
    },
    POOP_BAGS: {
      name: 'Home-compostable poop bags 60 pack',
    },
  };

  readonly OFFER = {
    header: 20,
  };

  readonly DATE_PICKER_FORMAT = 'dd/MM/yyyy';

  readonly LIFESTYLE_DISPLAY = {
    bootcamp_needed: 'Bootcamp needed',
    lazy_bones: 'Lazy Bones',
    typical: 'Typical',
    active: 'Active',
    hyperactive: 'Hyperactive',
  };

  readonly ERRORS: {
    GENERIC_SERVER_ERROR: 'Unknown error. Please try again later.';
  };
}

const AppConstants = new AppConstantsClass();

export default AppConstants;

import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as yup from 'yup';

import ButtonLoader from '../common/ButtonLoader';
import StatusMessage from '../common/StatusMessage';
import { StatusType } from '../../types';
import { resetPassword } from '../../services/auth.api';
import AppConstants from '../../AppConstants';

import * as styles from './reset-password.module.scss';

const ResetPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({
    message: '',
    type: StatusType.none,
    link: '',
    linkText: '',
  });

  const schema = yup.object({
    email: yup
      .string()
      .email()
      .required()
      .label('Email'),
  });

  const onSubmit = values => {
    setLoading(true);
    const { email } = values;
    resetPassword(email?.trim())
      .then(data => {
        setLoading(false);
        const status = {
          message:
            'You should receive an email shortly with the reset password link.',
          type: StatusType.success,
        };
        if (!data?.isUserFound) {
          status.message = 'User not found.';
          status.type = StatusType.error;
          status.link = '/getstarted';
          status.linkText = 'Get Started';
        }
        setStatus(status);
      })
      .catch(() => {
        setLoading(false);
        setStatus({
          message: AppConstants.ERRORS.GENERIC_SERVER_ERROR,
          type: StatusType.error,
        });
      });
  };

  return (
    <div className={styles.innerContainer}>
      <Row className="mgn-top-30">
        <Col className="text-center">
          <h2>Forgot Password?</h2>
          <div className={`mgn-top-30 mgn-bot-10 ${styles.subTitle}`}>
            <p>Don't worry, we've all been there.</p>
            <p>
              All you need to is enter your email address and follow the
              instructions in your inbox.
            </p>
          </div>
        </Col>
      </Row>
      <Formik
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={{
          email: '',
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Row className="mgn-top-30">
            <Col>
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="text-center cust-modal-footer mgn-top-20">
                  <ButtonLoader
                    className="btn-def"
                    type="submit"
                    text="Submit"
                    loading={loading}
                  />
                </div>
              </Form>
            </Col>
          </Row>
        )}
      </Formik>
      <StatusMessage
        message={status.message}
        type={status.type}
        link={status?.link}
        linkText={status?.linkText}
      />
    </div>
  );
};

export default ResetPasswordForm;

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'gatsby';

import ResetPasswordForm from './ResetPasswordForm';

import * as styles from './reset-password.module.scss';

const ResetPassword = () => {
  return (
    <div className={styles.container}>
      <Row className="mgn-top-40">
        <Col>
          <Link className="primary" to="/login">
            &lt; Back to Login
          </Link>
        </Col>
      </Row>
      <ResetPasswordForm />
    </div>
  );
};

export default ResetPassword;
